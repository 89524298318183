body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://cdn.syncfusion.com/ej2/20.2.43/tailwind.css");

@font-face {
  font-family: "AvenirLTProHeavy";
  src: local("AvenirLTProHeavy"), url("./assets/fonts/AvenirLTProHeavy.otf");
}

@font-face {
  font-family: "AvenirBlack";
  src: url("./assets/fonts/AvenirLTProBlack.otf");
}
@font-face {
  font-family: "AvenirMediam";
  src: local("AvenirMediam"), url("./assets/fonts/AvenirLTProMedium.otf");
}

@font-face {
  font-family: "AvenirLTProLight";
  src: url("./assets/fonts/AvenirLTProLight.otf");
}
@font-face {
  font-family: "AvenirLTProHeavyOblique";
  src: url("./assets/fonts/AvenirLTProHeavyOblique.otf");
}

::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
  color: #000 !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
::-webkit-scrollbar-thumb {
  background: #d1d1e0;
  width: 2px !important;
  border-radius: 10px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #b0b0bd !important;
  border-radius: 10px !important;
}
.scroller {
  width: 2px !important;
  height: 8px !important;
  color: #d1d1e0 !important;
  border-radius: 10px !important;
}
.scrollbar.thumb {
  color: #d1d1e0 !important;
  width: 2px !important;
  border-radius: 10px !important;
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #ccc #f5f5f5;
}

*::-moz-scrollbar {
  width: 12px;
}

*::-moz-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

*::-moz-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
}
