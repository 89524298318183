.MuiAutocomplete-root
  .MuiOutlinedInput-root.MuiInputBase-sizeSmall
  .MuiAutocomplete-input {
  padding: 0px 4px !important;
}
.MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 14px !important;
}


